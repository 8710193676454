<template>
    <div class="content">
        <div>
            <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="商家充值退费记录" :fetch="createExportData" :before-finish="finishDownload" worksheet="商家充值退费记录">
                <el-button type="primary" class="el-icon-download"> 导出</el-button>
            </JsonExcel>
        </div>

        <el-card style="margin-top:15px;width:100%" class="box-card">
          <el-row :gutter="10">
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <div class="search">
                <label>商家</label>
                <el-select v-model="searchForm.mc_id" style="min-width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="item in merDataList" :key="item.mc_id" :label="item.mc_name" :value="item.mc_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <div class="search">
                <label>优惠券</label>
                <el-select v-model="searchForm.dis_Id" style="min-width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="item in disDataList" :key="item.dr_id" :label="item.dr_name" :value="item.dr_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="12" :lg="8" :xl="8">
              <div class="search">
                <label>时间</label>
                <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <div class="search">
                <label>操作</label>
                <el-select v-model="searchForm.status" style="min-width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="充值" :value="1"></el-option>
                    <el-option label="退费" :value="2"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <div class="search">
                <el-button type="primary" plain @click="Search">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <el-table :data='LogDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="mr_time" label="时间">
                <template slot-scope="scope">
                    {{FormateTime(scope.row.mr_time)}}
                </template>
            </el-table-column>
             <el-table-column align="center" prop="mc_name" label="商家名称"></el-table-column>
             <el-table-column align="center" prop="dr_name" label="优惠券"></el-table-column>
             <el-table-column align="center" prop="mr_num" label="数量(张)"></el-table-column>
             <el-table-column align="center" prop="mr_charge" label="金额(元)"></el-table-column>
             <el-table-column align="center" prop="mr_state" label="操作">
                 <template slot-scope="scope">
                     {{scope.row.mr_state===0?"充值":"退费"}}
                 </template>
             </el-table-column>
             <el-table-column align="center" prop="user_name" label="操作员"></el-table-column>
        </el-table>
        <el-pagination style="width:100%" layout="prev, pager, next" @current-change="current_change" :total="total" background :page-size="searchForm.pageSize"></el-pagination>
    </div>
</template>

<script>
import util from '../../util/util'
import JsonExcel from 'vue-json-excel'

export default {
  data: function () {
    return {
      searchForm: {
        mc_id: 0,
        dis_Id: 0,
        time: [],
        status: 0,
        pageNo: 1,
        pageSize: 20
      },
      total: 0,
      merDataList: [],
      disDataList: [],
      LogDataList: [],

      // 导出
      exportDataStandard: {
        时间: {
          field: 'mr_time',
          callback: value => {
            return this.FormateTime(value)
          }
        },
        商家名称: 'mc_name',
        优惠券: 'dr_name',
        数量: 'mr_num',
        金额: 'mr_charge',
        操作: {
          field: 'mr_state',
          callback: value => {
            return value === 0 ? '充值' : '退费'
          }
        },
        操作员: 'user_name'
      },
      exportData: []
    }
  },
  components: {
    JsonExcel
  },
  computed: {
    FormateTime () {
      return function (time) {
        if(time===null||time===undefined||time==='')
            return ''
        else
            return util.FormateDate(time, 2)
      }
    }
  },
  created () {
    util.Get("merchant/getmerchantDataList").then(res=>{
      if(res.rpStatus===10000){
        this.merDataList = res.list[0]
        this.disDataList = res.list[1]
      }
    })
    this.GetDataList()
  },
  methods: {
    GetDataList () {
      this.searchForm.begintime=''
      this.searchForm.endtime=''
      if (this.searchForm.time!==null&& this.searchForm.time.length > 0) {
        this.searchForm.begintime = this.searchForm.time[0]
        this.searchForm.endtime = this.searchForm.time[1]
      }

      util.Get('logmerrecharge/getmerrechargelogdataList', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          this.LogDataList = res.list
          this.total = res.total
        }
      })
    },
    Search () {
      this.GetDataList()
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },
    // 导出
    async createExportData () {
      this.searchForm.pageNo = 0
      var form = JSON.parse(JSON.stringify(this.searchForm))
      form.pageNo = 0

      var result = await util.Get('logmerrecharge/getmerrechargelogdataList', form)
      if (result.rpStatus === 10000) {
        this.exportData = result.list
        return this.exportData
      }
    },
    finishDownload () {
      this.$message({
        message: '导出数据成功',
        type: 'success'
      })
      this.exportData=[]
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.search label{
  margin-right: 8px;

}
.el-table{
    margin-top: 10px;
}
</style>
